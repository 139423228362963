<template>
	<main class="page-container" id="sme-login">
		<div class="auth-component">
			<header class="auth-header">
				<h2 class="title">마케팅 동의 수신거부</h2>
			</header>
			<div class="auth-body text-center">
				<p class="mb-4 auth-body-desc">
					아래 버튼을 클릭하시면 수신동의<br />
					거부 처리됩니다.
				</p>
				<button type="button" class="button-default is-rounded is-large is-secondary">수신거부</button>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: 'MarketingUnsubscribe',
	data: () => ({}),
	computed: {},
	created() {},
	methods: {},
};
</script>
